import React from 'react'

export interface TrackingProps {
  category: string;
  label: string;
  listId?: string;
  listName?: string;
  lere?: {
    version: string | undefined;
  };
}

const TrackingContext = React.createContext<TrackingProps | undefined>(undefined)

export default TrackingContext

import React from 'react'
import LinePlaneFlyIcon from 'components/Luxkit/Icons/line/LinePlaneFlyIcon'
import { getDefaultAirportCode } from 'selectors/flightsSelectors'
import { connect } from 'react-redux'
import { getBundledFlightPricePerNight, getBundledWithFlightOfferDisplayPricing } from 'lib/offer/getOfferDisplayPricing'
import Group from 'components/utils/Group'
import PriceRowFlightPackage from 'components/Luxkit/PricePoints/PriceRowFlightPackage'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import LuxPlusPriceStack from 'luxPlus/components/LuxPlusPriceStack'
import { getOptimizelyExperimentVariation } from 'lib/optimizely/optimizelyUtils'
import { OptimizelyExperiments } from 'constants/optimizely'

interface Props {
  airportCode: string
  bundledWithFlightsOnly: boolean;
  flightPrices: { [airportCode: string]: number };
  price: number
  memberPrice: number
  value?: number
  duration: string
  hotelNights?: number
  isPerNightPricingTestEnabled: boolean
  offer?: App.AnyOffer;
}

function BundledHotelPriceContent({
  airportCode,
  bundledWithFlightsOnly,
  flightPrices,
  price,
  memberPrice,
  value,
  duration,
  hotelNights,
  isPerNightPricingTestEnabled,
  offer,
}: Props) {
  const flightPrice = flightPrices[airportCode]

  const result = getBundledWithFlightOfferDisplayPricing(bundledWithFlightsOnly, flightPrice, price, memberPrice, value ?? price)

  if (result.isBundledFlightPriceDisplayable) {
    const { packagedTotal, packagedMemberTotal, flightPrice } = isPerNightPricingTestEnabled && hotelNights ? getBundledFlightPricePerNight(result, hotelNights) : result
    return (
      <Group direction="vertical" gap={4}>
        <PriceRowPriceCaption startIcon={<LinePlaneFlyIcon />}>
          {duration}{flightPrice && ' + flights from'}
        </PriceRowPriceCaption>
        <LuxPlusPriceStack
          size="S"
          price={Math.ceil(packagedTotal!)}
          saleUnit="total*"
          memberPrice={Math.ceil(packagedMemberTotal!)}
          offerConfig={offer?.luxPlus}
        />
      </Group>

    )
  }

  return <PriceRowFlightPackage price={undefined} product="hotel" />
}

function mapStateToProps(state: App.State) {
  return {
    airportCode: getDefaultAirportCode(state),
    isPerNightPricingTestEnabled: !!getOptimizelyExperimentVariation(state, OptimizelyExperiments.pricePerNight),
  }
}

export default connect(mapStateToProps)(BundledHotelPriceContent)

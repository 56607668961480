import React, { useContext, useMemo, useRef, useCallback } from 'react'
import TrackingContext, { TrackingProps } from 'contexts/trackingContext'
import CardCarousel from 'components/Luxkit/Carousel/CardCarousel'
import debounce from 'lodash.debounce'
import { useInView } from 'react-intersection-observer'

interface Props extends React.ComponentProps<typeof CardCarousel> {}

function TrackedCardCarousel(props: Props) {
  const tracking = useContext(TrackingContext)
  const hasScrolledRight = useRef<boolean>(false)
  const hasScrolledLeft = useRef<boolean>(false)
  const currentLeft = useRef<number>(0)
  const hasClickedRight = useRef<boolean>(false)
  const hasClickedLeft = useRef<boolean>(false)
  const [inViewRef] = useInView({ triggerOnce: true })

  const newTracking = useMemo<TrackingProps | undefined>(() => {
    if (tracking && !tracking.label?.includes('Carousel')) {
      return ({
        ...tracking,
        label: `${tracking.label} - Carousel`,
      })
    }
    return tracking
  }, [tracking])

  const onScroll = useMemo(() => debounce(e => {
    if (newTracking) {
      const direction = e.target.scrollLeft > currentLeft.current ? 'Right' : 'Left'
      if ((direction === 'Right' && hasScrolledRight.current) ||
          (direction === 'Left' && hasScrolledLeft.current)) {
        return
      }

      if (direction === 'Right') {
        hasScrolledRight.current = true
      } else {
        hasScrolledRight.current = true
      }
    }
  }, 100), [newTracking])

  const onArrowCLick = useCallback((e, direction) => {
    const hasClicked = direction === 'left' ? hasClickedLeft.current : hasClickedRight.current
    if (newTracking && !hasClicked) {
      if (direction === 'left') {
        hasClickedLeft.current = true
      } else {
        hasClickedRight.current = true
      }
    }
  }, [newTracking])

  return (
    <TrackingContext.Provider value={newTracking}>
      <CardCarousel
        {...props}
        onArrowClick={onArrowCLick}
        onScroll={onScroll}
        ref={inViewRef}
      />
    </TrackingContext.Provider>
  )
}

export default TrackedCardCarousel

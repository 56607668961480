import React, { ReactNode } from 'react'
import { isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import { connect } from 'react-redux'

interface Props{
  children: React.ReactNode;
  showMemberPrice?: boolean;
  isLuxPlusEnabled: boolean;
  fallbackPrice?: ReactNode;
}

function LuxPlusPriceGuard(props: Props) {
  const { children, isLuxPlusEnabled, showMemberPrice, fallbackPrice } = props

  if (!isLuxPlusEnabled || !showMemberPrice) {
    return <>{fallbackPrice}</>
  }

  return <>{children}</>
}

function mapStateToProps(state: App.State) {
  return {
    isLuxPlusEnabled: isLuxPlusEnabled(state),
  }
}

export default connect(mapStateToProps)(LuxPlusPriceGuard)

import { MINIMUM_DISCOUNT_TO_SHOW_PERCENTAGE_BADGE } from 'constants/content'
import { safeDivideAndCeil } from 'lib/maths/mathUtils'
import getValueOffPercent from 'lib/offer/getValueOffPercent'

export function getHotelOfferDisplayedValue(bundledWithFlightsOnly: boolean, price: number, value: number, shouldDisplayValue: boolean, flightPrice?: number) {
  const bundledPrice = price + (flightPrice || 0)
  const bundledValue = value + (flightPrice || 0)
  const hotelDiscountPercent = getValueOffPercent(value, price)
  const bundledDiscountPercent = getValueOffPercent(bundledValue, bundledPrice)
  const highestDiscountPercent = Math.max(hotelDiscountPercent, bundledDiscountPercent)
  const shouldShowDiscountPercent = !bundledWithFlightsOnly && highestDiscountPercent >= MINIMUM_DISCOUNT_TO_SHOW_PERCENTAGE_BADGE

  return {
    shouldShowDiscountPercent,
    shouldShowValue: shouldDisplayValue && value > price,
    highestDiscountPercent,
  }
}

type PriceCanBeDisplayed<T> = {
  isBundledFlightPriceDisplayable: T;
}

type BundledFlightPrice = PriceCanBeDisplayed<true> & {
  packagedTotal?: number,
  packagedMemberTotal?: number,
  totalValue?: number,
  flightPrice?: number,
  shouldShowValue?: boolean,
}

type NoBundlePrice = PriceCanBeDisplayed<false>

export function getBundledWithFlightOfferDisplayPricing(bundledWithFlightsOnly: boolean, flightPrice: number, price: number, memberPrice: number, value: number): BundledFlightPrice | NoBundlePrice {
  if (bundledWithFlightsOnly && Number.isFinite(flightPrice)) {
    const totalValue = value + flightPrice
    const packagedTotal = price + flightPrice
    const packagedMemberTotal = memberPrice > 0 ? memberPrice + flightPrice : 0
    return { packagedTotal, packagedMemberTotal, totalValue, flightPrice, isBundledFlightPriceDisplayable: true }
  }
  return { isBundledFlightPriceDisplayable: false }
}

export function getBundledFlightPricePerNight(result: BundledFlightPrice, nights: number): BundledFlightPrice {
  return {
    ...result,
    packagedTotal: safeDivideAndCeil(result.packagedTotal, nights),
    packagedMemberTotal: safeDivideAndCeil(result.packagedMemberTotal, nights),
    totalValue: safeDivideAndCeil(result.totalValue, nights),
    flightPrice: safeDivideAndCeil(result.flightPrice, nights),
  }
}
